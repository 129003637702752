import React from 'react'
import About from '../pages/About'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Studies from '../pages/Studies'
import FAQ from '../pages/FAQ'
import Cathedrals from '../pages/Cathedrals'
import Cathedral from '../pages/Cathedral'
import Teachers from '../pages/Teachers'
import Teacher from '../pages/Teacher'
import Task from '../pages/Task'
import NotFound from '../pages/NotFound'
import Contact from '../pages/Contact'
import { useQuery } from "@tanstack/react-query";
import teacherService from "../services/teacherService";
import News from '../pages/News'
import New from '../pages/New'
import ForInquisitive from '../pages/ForInquisitive'
import Curiosity from '../pages/Curiosity'
import Quiz from '../pages/Quiz'
import Tasks from '../pages/Tasks'
import Schedule from '../pages/Schedule'
import Search from '../pages/Search'
import taskService from '../services/taskService'
import newsService from "../services/newsService";
import inquisitiveService from "../services/inquisitiveService";
import Library from '../pages/Library'
import Trophy from '../pages/Trophy'
import Test from '../pages/Test'
import StudentsList from '../pages/exam/StudentsList'
import TestLecturer from '../pages/TestLecturer'
import examService from '../services/examService'
import TeacherExam from '../pages/exam/TeacherExam'
import StudentExam from '../pages/exam/StudentExam'






export const publicRoutes = [
    {
        path: '/login',
        component: <Login />
    },
    {
        path: '/login/:status',
        component: <Login />
    }

]
function DynamicCathedraBreadcrumb({ match }) {
    const { chairLevel } = match.params
    console.log(chairLevel.split('-'))
    const text = chairLevel.split('-').map(word => isNaN(word) ? word !== 'i' ? word[0].toUpperCase() + word.substring(1) : word.toLowerCase() :  word + '.').join(' ')
    return (
        <span>{text}</span>
    )
};

function DynamicTeacherBreadcrumb({ match }) {
    const { id } = match.params

    const { data, isSuccess } = useQuery(
        ['teacher', id],
        teacherService.getSingleLecturer(id)
    );

    if (isSuccess) {
        const { firstName, lastName } = data;
        return (
            <span>{firstName} {lastName}</span>
        )
    }
    else return ''
};

const DynamicNewsBreadcrumb = ({ match }) => {
    const { id } = match.params
    const { data, isSuccess } = useQuery(
        ['new', id],
        newsService.getSingleNew(id)
    )
    return (isSuccess ? data.title : id)
}

const DynamicCuriosityBreadcrumb = ({ match }) => {
    const { id } = match.params
    const { data, isSuccess } = useQuery(
        ['inquisitive', id],
        inquisitiveService.getSingleInquisitive(id)
    )
    return (isSuccess ? data.title : id)
}

const DynamicTaskBreadcrumb = ({ match }) => {
    const { id } = match.params
    const {
        data: dataTask,
        isSuccess: isSuccessTasks,
    } = useQuery(
        ['task', id],
        taskService.getTaskDetails(id)
    )

    if (isSuccessTasks) {
        const { name } = dataTask
        return name
    }
    else return ''
}

// const DynamicExaminName = ({ match }) => {
//     const { id } = match.params
//     const {
//         data: dataExam,
//         isSuccess: isSuccessExam,
//     } = useQuery(
//         ['exam', id],
//         examService.getSingleExam(id),
//     )

//     return isSuccessExam ? dataExam.issue.name : ''
// }

// const DynamicExaminNameLecturer = ({ match }) => {
//     const { examId } = match.params
//     const {
//         data: dataStudents,
//         isSuccess: isSuccessStudents,
//     } = useQuery(
//         ['exam', examId, 'studentsList'],
//         examService.getAllStudentsByExamId(examId)
//     )


//     return isSuccessStudents ? dataStudents.exam.issue.name : ''
// }
const DynamicStudentName = ({ match }) => {
    const { examId, studentId } = match.params
    const {
        data: dataExam,
        isSuccess: isSuccessExam,
    } = useQuery(
        ['specyficExam', examId, studentId],
        examService.getSpecificExam(examId, studentId)
    )


    return isSuccessExam ? dataExam.examFinishedData.user.namesConcated : ''
}


export const privateRoutes = [
    {
        path: '/',
        breadcrumb: 'UBCM',
        component: <Home />
    },
    {
        path: '/about',
        breadcrumb: 'O Uniwersytecie',
        component: <About />
    },
    {
        path: '/about/teachers',
        breadcrumb: 'Wykładowcy',
        component: <Teachers />
    },
    {
        path: '/about/teachers/:id',
        breadcrumb: DynamicTeacherBreadcrumb,
        component: <Teacher />
    },
    {
        path: '/about/chairs',
        breadcrumb: '',
        component: <Cathedrals />
    },
    {
        path: '/about/chairs/:chairLevel',
        breadcrumb: DynamicCathedraBreadcrumb,
        component: <Cathedral />
    },
    {
        path: '/news',
        breadcrumb: 'Aktualności',
        component: <News />
    },
    {
        path: '/news/:id',
        breadcrumb: DynamicNewsBreadcrumb,
        component: <New />
    },
    {
        path: '/my-studies',
        breadcrumb: 'Moje studia',
        component: <Studies />
    },
    {
        path: '/my-studies/tasks',
        breadcrumb: 'Zadania',
        component: <Tasks />
    },
    {
        path: '/my-studies/tasks/:id',
        breadcrumb: DynamicTaskBreadcrumb,
        component: <Task />
    },
    {
        path: '/my-studies/tasks/:id/quiz',
        breadcrumb: 'Quiz',
        component: <Quiz />
    },
    {
        path: '/faq',
        breadcrumb: 'FAQ',
        component: <FAQ />
    },
    {
        path: '/contact',
        breadcrumb: 'Kontakt',
        component: <Contact />
    },
    {
        path: '/for-inquisitive',
        breadcrumb: 'Dla dociekliwych',
        component: <ForInquisitive />
    },
    {
        path: '/for-inquisitive/:id',
        breadcrumb: DynamicCuriosityBreadcrumb,
        component: <Curiosity />
    },
    {
        path: '/my-studies/schedule',
        breadcrumb: 'Terminarz',
        component: <Schedule />
    },
    {
        path: '/my-studies/library',
        breadcrumb: 'Biblioteka',
        component: <Library />
    },
    {
        path: '/search',
        breadcrumb: 'Wyszukiwarka',
        component: <Search />
    },
    {
        path: '/trophy',
        breadcrumb: 'Trofea',
        component: <Trophy />
    },
    {
        path: '/my-studies/exams',
        breadcrumb: 'Egzaminy',
        component: <StudentExam />
    },
    {
        path: '/my-studies/exams/:id',
        breadcrumb: "Egzamin",
        component: <Test />
    },

    //---------------------------

    {
        path: '/my-studies/lecturer-exams',
        breadcrumb: 'Egzaminy',
        component: <TeacherExam />
    },

    {
        path: '/my-studies/lecturer-exams/:examId',
        breadcrumb: 'Egzamin',
        component: <StudentsList />
    },


    {
        path: '/my-studies/lecturer-exams/:examId/students',
        breadcrumb: '',
        component: <NotFound />
    },

    {
        path: '/my-studies/lecturer-exams/:examId/students/:studentId',
        breadcrumb: DynamicStudentName,
        component: <TestLecturer />
    },

    {
        path: '*',
        breadcrumb: '404',
        component: <NotFound />
    },


]

