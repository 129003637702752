import React from 'react'
import Container from '../components/Container'
import { useQuery } from '@tanstack/react-query'
import teacherService from '../services/teacherService'
import { useParams } from 'react-router-dom'
import BigTitle from '../components/BigTitle'
import styled from 'styled-components'
import Category from '../components/Category'
import FeatureList from '../components/FeatureList'
import TaskRow from '../components/TaskRow'
import Slider from '../components/Slider'
import Card from '../components/Card'
import { slide1Data } from '../FakeData/SliderData'
import Stages from '../components/Stages'
import ChairCard from "../components/ChairCard";
import taskService from '../services/taskService'
import Spiner from '../components/Spiner'


const Teacher = () => {

    const { id } = useParams()
    const {
        data: dataTeacher,
        isSuccess: isSuccessTeacher,
        isLoading: isLoadingTeachers
    } = useQuery(
        ['teacher', id],
        teacherService.getSingleLecturer(id)
    );

    const {
        data: dataTasksList,
        isSuccess: isSuccessTasksList,
        isLoading: isLoadingTasksList
    } = useQuery(
        ['lecturerTasks', id],
        taskService.getLecturerTasks(id)
    )

    const isSuccess = isSuccessTeacher && isSuccessTasksList
    const isLoading = isLoadingTeachers || isLoadingTasksList

    return (
        <>
            {
                isLoading && <Spiner />
            }
            {
                isSuccess && (
                    <>
                        <Container>
                            <Wrapper>
                                {
                                    isSuccessTeacher.title ? (
                                        <BigTitle>{dataTeacher.title} <br />{`${dataTeacher.firstName} ${dataTeacher.lastName}`}</BigTitle>
                                    ) : (
                                        <BigTitle>{`${dataTeacher.firstName} ${dataTeacher.lastName}`}</BigTitle>
                                    )
                                }
                                <Row>
                                    <Description>
                                        <Sentence>{dataTeacher.sentence}</Sentence>
                                        <About>{dataTeacher.about}</About>
                                    </Description>
                                    <TeacherImage src={dataTeacher.image} alt='teacher image' />
                                </Row>
                            </Wrapper>
                        </Container>
                        <Category title='Powiązane zagadnienia' />
                        <Container >
                            <Wrapper>
                                <StyledFeatureList data={dataTeacher.issues} />
                            </Wrapper>
                        </Container>
                        <Anchor id={"related-tasks"} ></Anchor>
                        <Category title='Powiązane zadania' />
                        <Slider
                            data={dataTasksList}
                            component={<TaskRow abstractSize={'small'} />}
                        />
                        <Anchor id={"related-chairs"} ></Anchor>
                        <Category title='Powiązane katedry' />
                        <RelatedCathedralsRow>
                            {
                                dataTeacher.universityChairs.filter(el => el.universityChairLevels.length > 0).map((catedral, index) => (
                                    <ChairCard
                                        data={catedral}
                                        key={index}
                                    />
                                ))
                            }
                        </RelatedCathedralsRow>
                        <StyledStages />
                        <Slider
                            data={slide1Data}
                            component={<Card />}
                            breakpoints={{
                                1400: {
                                    slidesPerView: 3
                                },
                                900: {
                                    slidesPerView: 2
                                },
                            }}
                            isCenter={true}
                            bgColor={'lightGray'}
                        />
                    </>
                )
            }
        </>
    )
}

export default Teacher

const Anchor = styled.div`
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
`

const Wrapper = styled.div`
    max-width: 1170px;
    margin: auto;
`

const Row = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px;
    @media only screen and (max-width: 700px) {
        flex-wrap: wrap;
        justify-content: center; 
    }
`


const Description = styled.div`
    max-width: 700px;
`

const Sentence = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 38px;
    color: ${props => props.theme.colors.primary};
    font-family: 'Merriweather', serif;
    margin-bottom: 56px;
`


const About = styled.div`
    font-size: 20px;
    line-height: 32px;
`

const TeacherImage = styled.img`
  border: 1px solid ${props => props.theme.colors.yellowGreen};
`

const StyledFeatureList = styled(FeatureList)`
    margin: 40px 0;
`

const RelatedCathedralsRow = styled.div`
    margin-top: 26px;
    display: flex;
    justify-content: center;
    gap: 60px;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        gap: 45px;
    }
`

const StyledStages = styled(Stages)`
    padding-top: 40px;
    padding-bottom: 40px;
`

