import React, { useState } from 'react'
import BigTitle from '../components/BigTitle'
import Container from '../components/Container'
import TaskRow from '../components/TaskRow'
import Title from '../components/Title'
import { useQuery } from '@tanstack/react-query'
import styled from 'styled-components'
import Pagination from '../components/Pagination'
import taskService from '../services/taskService'
import Spiner from '../components/Spiner'

const Tasks = () => {
    const perPage = 5
    const [currentPage, setCurrentPage] = useState(1)

    const {
        data: dataUserTasks,
        isSuccess: isSuccessUserTasks,
        isLoading: isLoadingUserTasks
    } = useQuery(
        ['userTasks'],
        taskService.getUserTasks()
    )

    return (
        <>
            {
                isLoadingUserTasks && <Spiner />
            }
            {
                isSuccessUserTasks && (
                    <>
                        <Container>
                            <BigTitle>Zadania</BigTitle>
                            <Title>Przypisane do Ciebie</Title>
                        </Container>
                        {
                            dataUserTasks.filter(task => task.published).sort((a,b) => new Date(b.createdAtText) - new Date(a.createdAtText)).map((task, index) => (index < currentPage * perPage) && (index >= (currentPage - 1) * perPage) && <TaskRow key={index} data={task} />)
                        }
                        <Container disablePaddingTop>
                            {dataUserTasks.filter(task => task.published).length > 0 && (
                                <PagginationWrapper>
                                <Pagination
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pages={dataUserTasks.filter(task => task.published).length}
                                    perPage={perPage}
                                />
                            </PagginationWrapper>
                            )}
                        </Container>
                    </>
                )
            }

        </>
    )
}

export default Tasks

const PagginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    margin-top: 40px;
`