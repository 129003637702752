import React, { useState } from 'react'
import Container from '../components/Container'
import BigTitle from '../components/BigTitle'
import Title from '../components/Title'
import styled from 'styled-components'
import Description from '../components/Description'
import teachers from '../assets/images/teachers.jpg'
import TeacherRow from '../components/TeacherRow'
import teacherService from '../services/teacherService'
import { useQuery } from '@tanstack/react-query'
import Slider from '../components/Slider'
import Card from '../components/Card'
import { slide1Data } from '../FakeData/SliderData'
import Stages from '../components/Stages'
import Pagination from '../components/Pagination'
import Spiner from '../components/Spiner'


const Teachers = () => {


    const [currentPage, setCurrentPage] = useState(1)
    const perPage = 6

    const {
        data: dataTeachers,
        isSuccess: isSuccessTeachers,
        isLoading: isLoadingTeachers
    } = useQuery(
        ['teachers'],
        teacherService.getAllLecturers(),
    )


    return (
        <>
            {
                isLoadingTeachers && <Spiner />
            }
            {
                isSuccessTeachers && (
                    <>
                        <Container>
                            <Wrapper>
                                <BigTitle>
                                    Nasi wykładowcy
                                </BigTitle>
                                <Title>Ucz się od najlepszych</Title>
                                <Row>
                                    <Description>
                                        Wszystkie zagadnienia i zadania na Uniwersytecie Berlin-Chemie Menarini przygotowywane są przez specjalistów i ekspertów z ogromnym doświadczeniem. Korzystaj z praktycznej wiedzy naszych mentorów i rozwijaj się razem z UBCM.
                                    </Description>
                                    <Image src={teachers} alt='teachers' />
                                </Row>
                            </Wrapper>
                        </Container>
                        {
                            dataTeachers.map((teacher, index) => (index < currentPage * perPage) && (index >= (currentPage - 1) * perPage) && < TeacherRow key={index} data={teacher} />)
                        }
                        <PagginationWrapper>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pages={dataTeachers.length}
                                perPage={perPage}
                            />
                        </PagginationWrapper>
                        <StylesStages noBackground />
                        <Slider
                            data={slide1Data}
                            component={<Card />}
                            breakpoints={{
                                1400: {
                                    slidesPerView: 3
                                },
                                900: {
                                    slidesPerView: 2
                                },
                            }}
                            isCenter={true}
                            bgColor={'lightGray'}
                        />
                    </>
                )
            }
        </>
    )
}

export default Teachers

const Wrapper = styled.div`
    max-width: 1160px;
    margin: auto; 
`

const Row = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;
    @media only screen and (max-width: 800px) {
        flex-wrap: wrap; 
    }
`

const Image = styled.img`
    display: block;
    max-width: 400px;
    width: 100%;
`

const PagginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    margin-top: 40px;
`

const StylesStages = styled(Stages)`
    padding-bottom: 40px;
`

