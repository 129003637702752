import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import RadioBox from './RadioBox'

const QuizQuestion = ({ quizQuestions, number, data, register, stage }) => {
    const { question, quizAnswers } = data


    const handleStatus = (index) => {
        if (quizQuestions) {
            if (quizQuestions.quizAnswers[index].checked === true && quizQuestions.quizAnswers[index].correct === true) {
                return 'good'
            }
            else if (quizQuestions.quizAnswers[index].checked === true && quizQuestions.quizAnswers[index].correct === false) {
                return 'wrong'
            }
            else if (quizQuestions.quizAnswers[index].checked === false && quizQuestions.quizAnswers[index].correct === true) {
                return 'good'
            }
            else if (quizQuestions.quizAnswers[index].checked === false && quizQuestions.quizAnswers[index].correct === false) {
                return 'disabled'
            }
        }
        else {
            return null
        }
    }

    return (
        <div>
            <Number disablePaddingTop>
                <NumberWrapper>
                    Pytanie {number}
                </NumberWrapper>
            </Number>
            <Container disablePaddingTop>
                <Wrapper>
                    <Question>
                        {question}
                    </Question>
                    <Answers>
                        {
                            quizAnswers.map((answer, index) => (
                                <RadioBox
                                    {...register(`question-${number}`)}
                                    value={answer.id}
                                    key={index}
                                    text={answer.answer}
                                    status={handleStatus(index)}
                                    disabled={stage === 1}
                                />
                            ))
                        }
                    </Answers>
                </Wrapper>
            </Container>
        </div>
    )
}

export default QuizQuestion

const Number = styled(Container)`
    background-color: ${props => rgba(props.theme.colors.primary, 0.19)};
`

const NumberWrapper = styled.div`
    max-width: 1170px;
    margin: auto;
    height: 74px;
    display: flex;
    align-items: center;
`

const Wrapper = styled.div`
    max-width: 1170px;
    margin: auto;
`

const Question = styled.div`
    font-size: 24px;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 30px;
    margin-top: 15px;
`

const Answers = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 40px;
`
