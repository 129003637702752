import React from 'react';
import googleCalendar from "../assets/images/googleCalendar.png";
import styled from "styled-components";

const AddToGoogleCalendarButton = ({ title, startDate, endDate, startTime = "09:00", endTime = "17:00", details, location }) => {
    const formatDateToUTC = (date, time) => {
        const dateObj = new Date(`${date}T${time}:00`);
        return dateObj.toISOString().replace(/-|:|\.\d+/g, '');
    };

    const handleAddToCalendar = () => {
        const formattedStartDate = formatDateToUTC(startDate, startTime);
        const formattedEndDate = formatDateToUTC(endDate, endTime);

        const calendarUrl = `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(title)}&dates=${formattedStartDate}/${formattedEndDate}&details=${encodeURIComponent(details)}&location=${encodeURIComponent(location)}&sf=true&output=xml`;

        window.open(calendarUrl, '_blank');
    };

    return (
    <StyledTag>
        <button onClick={handleAddToCalendar} style={{border: '0'}}>
            <img src={googleCalendar} alt='googleCalendar' width={50} />
            <Text>Dodaj do kalendarza</Text>
        </button>
    </StyledTag>
    );
};

export default AddToGoogleCalendarButton;

const Text = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(-20%, -50%);
    min-width: 100%;
    background-color: white;
    border: 1px solid ${props => props.theme.colors.primary};
    text-align: center;
    padding: 5px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
`

const StyledTag = styled.div`
    width: 86px;
    height: 86px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        ${Text} {
            opacity: 1;
        }
    }
`