const menuData = [
    {
        label: 'O Uniwersytecie',
        link: '/about',
        collapse: [
            {
                label: 'Katedry',
                link: '/about/chairs',
                disabled: false
            },
            {
                label: 'Nasi wykładowcy',
                link: '/about/teachers',
                disabled: false
            },
            {
                label: 'Dla dociekliwych',
                link: '/for-inquisitive',
                disabled: false
            }
        ]
    },
    {
        label: 'Aktualności',
        link: '/news/',
        disabled: false
    },
    {
        label: 'Moje studia',
        link: '/my-studies',
        collapse: [
            {
                label: 'Zadania',
                link: '/my-studies/tasks',
                disabled: false
            },
            {
                label: 'Program',
                link: '/DYNAMIC_CHAIR',
                disabled: false
            },
            /*{
                label: 'Terminarz',
                link: '/my-studies/schedule',
                disabled: false
            },*/
            {
                label: 'Biblioteka',
                link: '/my-studies/library',
                disabled: false
            },
            {
                label: 'Trofea',
                link: '/trophy',
                disabled: false
            },
            {
                label: 'Kontakt',
                link: '/contact',
                disabled: false
            },
/*            {
                label: 'Egzaminy',
                link: '/my-studies/exams',
                disabled: true
            },*/
        ],
        disabled: false
    },
]

export default menuData