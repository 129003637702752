import React, { useState } from 'react'
import BigDescription from '../components/BigDescription'
import BigTitle from '../components/BigTitle'
import Container from '../components/Container'
import Title from '../components/Title'
import styled from 'styled-components'
import director from '../assets/images/director.jpg'
import videoCover from '../assets/images/videoCover.png'
import Description from '../components/Description'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '../Hooks/MediaQueryHook'
import theme from '../theme'
import Modal from '../components/Modal'
import NewRow from '../components/NewRow'
import Stages from '../components/Stages'
import Slider from '../components/Slider'
import { slide1Data } from '../FakeData/SliderData'
import Card from '../components/Card'
import bgYellow from '../assets/images/teacherRowBg.png'
import {useQuery} from "@tanstack/react-query";
import newsService from "../services/newsService";
import userService from "../services/userService";
// import Pagination from '../components/Pagination'

const perPage = 1

const Home = () => {
    // eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState(1)
    const isMobile = useMediaQuery(`(max-width: ${theme.mobile}`)

    const {
        data: dataUserMe,
        isSuccess: isSuccessUserMe,
    } = useQuery(
        ['userMe'],
        userService.userMe()
    )

    const { data, isSuccess } = useQuery(
        ['news'],
        newsService.getAllNews(),
    )

    return (
        <>
            <StyledContainer>
                <Wrapper>
                    <BigTitle>
                        Uniwersytet<br />Berlin-Chemie Menarini
                    </BigTitle>
                    {
                        isMobile && (
                            <StyledTitle >
                                Gratuluję!
                            </StyledTitle>
                        )
                    }
                    {isSuccessUserMe &&
                        <>
                            {dataUserMe.universityChairLevel.halfSet.half.edition.symbol === '1' ? <IntroductionLetter1/> : <IntroductionLetter2/>}
                        </>
                    }
                </Wrapper>
            </StyledContainer >
            {
                isSuccess &&
                data.map((item, index) => (index < currentPage * perPage) && (index >= (currentPage - 1) * perPage) &&  <NewRow key={index} data={item} bg={bgYellow} link={`/news/${item.id}`} />)
            }
            <Container disablePaddingTop>
                {/* <PagginationWrapper>
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pages={data.length} perPage={perPage} />

                </PagginationWrapper> */}
            </Container>
            {/* <NewRow data={newData} /> */}
            <StyledStages noBackground />
            <Slider
                data={slide1Data}
                component={<Card />}
                breakpoints={{
                    1400: {
                        slidesPerView: 3
                    },
                    900: {
                        slidesPerView: 2
                    },
                }}
                isCenter={true}
                bgColor={'lightGray'}
            />
        </>
    )
}

const IntroductionLetter1 = () => {
    const isMobile2 = useMediaQuery(`(max-width: 1136px`)
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const isMobile = useMediaQuery(`(max-width: ${theme.mobile}`)

    return (
        <Row>
        <Col>
            {
                !isMobile && (
                    <StyledTitle >
                        Gratuluję!
                    </StyledTitle>
                )
            }
            <StyledBigDescrition>
                Jeżeli czytasz te słowa to oznacza, że przeszliśmy już razem długą drogę w Berlin-Chemie Menarini i jesteś w grupie osób nominowanych do udziału w szczególnym projekcie rozwoju nowych umiejętności - <Span>Uniwersytecie Berlin-Chemie Menarini</Span>.
            </StyledBigDescrition>
            <VideoCover
                onClick={() => setIsOpen(true)}
                src={videoCover}
                alt={'BCM FILM'}
            />

            <Modal isOpen={isOpen} setIsOpen={setIsOpen} >
                <div
                    style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe
                        title='BCM FILM'
                        src="https://player.vimeo.com/video/740765072?h=08ea70eb73&autoplay=1"
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        frameBorder={0}
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            </Modal>

            <StyledDescription1>
                Wartości, którymi się kierujemy mają odzwierciedlenie w konkretnych działaniach. To ludzie stanowią nasz kapitał. Razem budujemy naszą tożsamość i razem budujemy przyszłość. Dlatego, z ogromną przyjemnością witam Cię w projekcie, który będzie wspierał rozwój Twoich talentów.
            </StyledDescription1>
            <StyledDescription2>
                Rozwój to inwestycja nie tylko w podnoszenie swoich kompetencji zawodowych,
                ale także osobistych. Cykl szkoleń i zadań, które przygotowaliśmy dla Ciebie,
                zawiera różnorodne metody i treści, mające obudzić kreatywność i odwagę{!isMobile2 && <br />} do poszukiwania nowych rozwiązań.


            </StyledDescription2>
            <StyledDescription3>
                Naszym celem w codziennej pracy jest przekraczanie własnych granic, aby stale podnosić wartość tego, co udaje nam się budować.
            </StyledDescription3>
            <StyledDescription4>
                Odpowiedzialność za swój rozwój każdy trzyma w swoich rękach, ale jako organizacja, chcemy dawać przestrzeń i narzędzia, by wspierać jego realizację.
            </StyledDescription4>
            <Description>
                Życzę powodzenia i mocno kibicuję!
            </Description>
            <StyledButton onClick={() => navigate('/about')}>Przejdź dalej</StyledButton>


        </Col>
        <Col>

            <CardImage src={director} alt='director' />
            <CardTitle>Joanna Kawecka</CardTitle>
            <CardDesc>
                Dyrektor Generalna <br />
                Berlin-Chemie Menarini
            </CardDesc>

        </Col>
    </Row>
)}

const IntroductionLetter2 = () => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery(`(max-width: ${theme.mobile}`)

    return (
        <Row>
            <Col>
                {
                    !isMobile && (
                        <StyledTitle >
                            Gratuluję!
                        </StyledTitle>
                    )
                }
                <StyledBigDescrition>
                    Jeżeli czytasz te słowa, to oznacza, że przeszliśmy już razem długą drogę w Berlin-Chemie Menarini i jesteś w grupie osób zaproszonych do udziału w szczególnym projekcie rozwoju nowych umiejętności  - <Span>Uniwersytecie Berlin-Chemie Menarini</Span>.
                </StyledBigDescrition>
                <StyledDescription1>
                    Wartości, którymi się kierujemy, mają odzwierciedlenie w konkretnych działaniach. Ludzie stanowią nasz kapitał. Razem budujemy naszą tożsamość i razem budujemy przyszłość. Dlatego z ogromną przyjemnością witam Cię w projekcie, który będzie wspierał rozwój Twoich talentów.
                </StyledDescription1>
                <StyledDescription2>
                    Rozwój  to inwestycja nie tylko w podnoszenie swoich kompetencji zawodowych, lecz także osobistych. Cykl szkoleń i zadań, jakie przygotowaliśmy dla Ciebie, zawiera różnorodność metod i treści, by budzić kreatywność i odwagę do poszukiwania nowych rozwiązań.
                </StyledDescription2>
                <StyledDescription3>
                    W codziennej pracy naszym celem jest przekraczanie własnych granic, aby stale podnosić wartość tego, co udaje nam się budować.
                </StyledDescription3>
                <StyledDescription4>
                    Odpowiedzialność za swój rozwój każdy trzyma w swoich rękach, ale jako organizacja chcemy dawać przestrzeń i narzędzia, by wspierać jego realizację.
                </StyledDescription4>
                <Description>
                    Życzę powodzenia i mocno kibicuję!
                </Description>
                <StyledButton onClick={() => navigate('/about')}>Przejdź dalej</StyledButton>
            </Col>
            <Col>
                <CardImage src={director} alt='director' />
                <CardTitle>Joanna Kawecka</CardTitle>
                <CardDesc>
                    Dyrektor Generalna <br />
                    Berlin-Chemie Menarini
                </CardDesc>

            </Col>
        </Row>
    )}

export default Home

const StyledBigDescrition = styled(BigDescription)`
    padding-right: 60px;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        padding-right: 0px;
    }
`

const StyledDescription1 = styled(Description)`
    padding-right: 30px;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        padding-right: 0px;
    }    
`

const StyledDescription2 = styled(Description)`
    padding-right: 50px;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        padding-right: 0px;
    }
`

const StyledDescription3 = styled(Description)`
    padding-right: 125px;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        padding-right: 0px;
    }
`

const StyledDescription4 = styled(Description)`

`

const Span = styled.span`
    color: ${props => props.theme.colors.primary};
    font-family: 'Merriweather', serif;

`
/*const VideoCover = styled.img`
    margin-bottom: 30px;
    max-width: 525px;
    width: 100%;
    cursor:pointer;
    opacity: 0.8;
    transition: .3s;
    &:hover {
        opacity: 1;
    }
`*/


const Col = styled.div`

`

const VideoCover = styled.img`
    margin-bottom: 30px;
    max-width: 525px;
    width: 100%;
    cursor:pointer;
    opacity: 0.8;
    transition: .3s;
    &:hover {
        opacity: 1;
    }
`


const CardImage = styled.img`
    width: 280px;
    border: 1px solid ${props => props.theme.colors.yellowGreen};
    margin-bottom: 30px;
`

const CardTitle = styled.p`
    font-family: 'Merriweather', serif;
    font-size: 17px;
    margin-bottom: 30px;
    font-weight: bold;
`

const CardDesc = styled.p`
    font-family: 'Merriweather', serif;
    font-size: 20px;
`

const StyledButton = styled(Button)`
    margin-left: auto;
`

const Row = styled.div`
    display: flex;
    gap: 56px;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        gap: 19px;
    }
`

const Wrapper = styled.div`
    margin: auto;
    max-width: 1100px;
`

const StyledTitle = styled(Title)`
    text-align: left;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        text-align: center;
    }
`

const StyledContainer = styled(Container)`
  @media only screen and (max-width: ${props => props.theme.mobile}) {
    ${Row} {
      flex-direction: column-reverse;
    }

    ${StyledButton} {
      margin: 50px auto 30px auto;
    }

    ${Col} {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ${CardImage} {
      margin-bottom: 15px;
    }

    ${CardImage} {
      width: 140px;
    }

    ${CardTitle} {
      text-align: center;
      margin-bottom: 15px;
    }

    ${CardDesc} {
      text-align: center;
    }
  }
}
`

const StyledStages = styled(Stages)`
    padding: 40px 0px; 
`

// const PagginationWrapper = styled.div`
//     display: flex;
//     justify-content: center;
//     margin-bottom: 80px;
//     margin-top: 40px;
// `


