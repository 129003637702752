import React from 'react'
import styled from 'styled-components'
import work from '../assets/images/work.svg'
import read from '../assets/images/read.svg'
import movie from '../assets/images/movie.svg'
import team from '../assets/images/team.svg'
import display from '../assets/images/display.svg'
import computer from '../assets/images/computer.svg'
import fullTime from '../assets/images/full-time.svg'

const Tag = ({ data }) => {
    const { name } = data

    const image = (name) => {
        if (name === 'PRACTICE') {
            return work
        }
        if (name === 'THEORY') {
            return read
        }
        if (name === 'MOVIE') {
            return movie
        }
        if (name === 'TEAM') {
            return team
        }
        if (name === 'DISPLAY') {
            return display
        }
        if (name === 'FULLTIME') {
            return fullTime
        }
        if (name === 'READ ON-LINE') {
            return computer
        }
    }

    const changeText = (name) => {
        if (name === 'PRACTICE') {
            return 'praca indywidualna'
        }
        if (name === 'THEORY') {
            return 'przeczytaj książkę'
        }
        if (name === 'MOVIE') {
            return 'obejrzyj online'
        }
        if (name === 'TEAM') {
            return 'praca zespołowa'
        }
        if (name === 'DISPLAY') {
            return 'zajęcia online'
        }
        if (name === 'READ ON-LINE') {
            return 'przeczytaj online'
        }
        if (name === 'FULLTIME') {
            return 'zajęcia stacjonarne'
        }
    }

    return (
        <StyledTag>
            <Background />
            <Icon src={image(name)}></Icon>
            <Text>{changeText(name)}</Text>
        </StyledTag>
    )
}

export default Tag



const Text = styled.div`
    position: absolute;
    top: 50%;
    transform translateY(-50%);
    min-width: 100%;
    background-color: white;
    border: 1px solid ${props => props.theme.colors.primary};
    text-align: center;
    padding: 5px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
`

const Icon = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
`

const Background = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(0,122,129,1) 50%, rgba(226,0,15,1) 50%);
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
`

const StyledTag = styled.div`
    width: 86px;
    height: 86px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        ${Text} {
            opacity: 1;
        }
        ${Background} {
            opacity: 0.3
        }
        ${Icon} {
            opacity: 0.3;
        }
    }
`





