import { action, persist } from 'easy-peasy'


const userStore = persist(
    {
        chairLevelSlug: null,
        edition: null,
        setChairLevelSlug: action((state, payload) => {
            state.chairLevelSlug = payload
        }),
        setEdition: action((state, payload) => {
            state.edition = payload
        }),
    },
    {
        storage: localStorage,
    },
)

export default userStore
