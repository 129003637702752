import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'
import BigTitle from '../components/BigTitle'
import Title from '../components/Title'
import Button from '../components/Button'
import Container from '../components/Container'
import chairService from '../services/chairService'
import { useQuery } from '@tanstack/react-query'
import FeatureList from '../components/FeatureList'
import Spiner from '../components/Spiner';


const Cathedral = () => {

  const { chairLevel } = useParams()

  const navigate = useNavigate()

  const {
    data: dataChairLevel,
    isSuccess: isSuccessChairLevel,
    isLoading: isLoadingChairLevel
  } = useQuery(
    ['chair', chairLevel],
    chairService.getSingleChairLevelBySlug(chairLevel),
  )

  return (
    <>
      {
        isLoadingChairLevel && <Spiner />
      }
      {
        isSuccessChairLevel && (
          <Container>
            <BigTitle>
              {dataChairLevel.universityChair.name}
            </BigTitle>
            <Title>
              {dataChairLevel.level.name}
            </Title>
            <Content>
              <Row>
                <div className={'description'}>
                  <p>
                    {dataChairLevel.universityChair.shortDescription}
                  </p>
                  {dataChairLevel.customHtml &&
                  <StudentsList>
                    <h3>Lista Studentów:</h3>
                    <div dangerouslySetInnerHTML={{ __html: dataChairLevel.customHtml }}></div>
                  </StudentsList>
                  }
                </div>
                <div className="image">
                  <img src={dataChairLevel.image} alt='chair' />
                </div>
              </Row>
              <Row>
                <ListedSection>
                  <FeatureList title={'Zagadnienia jakie poznasz:'} data={dataChairLevel.issues} />
                </ListedSection>
              </Row>
            </Content>
            <StyledButton
              onClick={() => navigate('/about/chairs')}
            >
              Pozostałe katedry
            </StyledButton>
          </Container>
        )
      }
    </>
  )
}

export default Cathedral

const Content = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  @media (max-width: ${props => props.theme.mobile}) {
    flex-direction: column;
    .image {
      margin: 0 auto;
      display: block;
    }
    .description {
      text-align: center;
    }
  }

  .description {
    p {
      font-size: 20px;
      line-height: 32px;
    }
  }

  .image {
    min-width: 340px;
    max-width: 340px;

    @media (max-width: ${props => props.theme.mobile}) {
      min-width: 450px;
      max-width: 450px;
    }
    @media (max-width: 478px) {
      width: calc(100% + 40px);
      margin: 0 -30px;
      min-width: initial;
      max-width: initial;
    }
    img {
      width: 100%;
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 46px auto 0px;
  gap: 30px;
  @media (max-width: ${props => props.theme.mobile}) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  margin: 30px auto 0 auto;
`

const ListedSection = styled.div`
  display: flex;
  flex-direction: column;
`

const StudentsList = styled.div`
  margin-top: 40px;
  h3 {
    font-size: 24px;
    color: ${props => props.theme.colors.primary};
    line-height: 33px;
    margin-bottom: 40px;
    @media (max-width: ${props => props.theme.mobile}) {
      text-align: center;
    }
  }
  ul {
    list-style-type: decimal;
    padding-left: 20px;
    li {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
`