import React from 'react'
import styled from 'styled-components'
import BigTitle from '../components/BigTitle'
import Container from '../components/Container'
import chairService from '../services/chairService'
import { useQuery } from '@tanstack/react-query'
import ChairCard from '../components/ChairCard'
import Stages from "../components/Stages";
import Spiner from '../components/Spiner'

const Cathedrals = () => {
    const {
        data: dataChairs,
        isSuccess: isSuccessChairs,
        isLoading: isLoadingChairs
    } = useQuery(
        ['chairs'],
        chairService.getAllChairs()
    )

    return (
        <>
            {
                isLoadingChairs && <Spiner />
            }
            {
                isSuccessChairs && (
                    <>
                        <Container>
                            <BigTitle>
                                Katedry
                            </BigTitle>
                            <Row>
                                {
                                    dataChairs.map((chair, index) => (
                                        <ChairCard
                                            data={chair}
                                            key={index}
                                        />
                                    ))
                                }
                            </Row>
                        </Container>
                        <StyledStages noBackground />
                    </>
                )
            }
        </>
    )
}

export default Cathedrals

const Row = styled.div`
    display: flex;
    justify-content: center;
    gap: 60px;
    flex-wrap: wrap;
    @media only screen and (max-width: ${props => props.theme.mobile}) {
        gap: 45px;
    }
`
const StyledStages = styled(Stages)`
    padding-top: 40px;
    padding-bottom: 40px;
`