import React from 'react'
import styled, { css } from 'styled-components'
import teacherRowBg from '../assets/images/teacherRowBg.png'
import Tag from './Tag'
import Button from './Button'
import Container from './Container'
import Link from "./Link";


const TaskRow = ({ extraComponent, extraLine, data, noBackground, noButton, abstractSize, ...props }) => {

    const { id, createdAtText, name, abstract, image, tags, lecturer, additional } = data

    const getLecturerName = () => {
        if(data.fullName) {
            return data.fullName
        } else {
            if(lecturer?.title === null)
                return `${lecturer?.firstName} ${lecturer?.lastName}`;
            else
                return `${lecturer?.title} ${lecturer?.firstName} ${lecturer?.lastName}`;
        }
    }


    return (
        <StyledTask noBackground={noBackground} {...props}>
            <Container disablePaddingTop>
                <Row extraLine={extraLine}>
                    <Col>
                        <Time>{createdAtText}</Time>
                        {name && <Title additional={additional}>{name}</Title>}
                        <FullName>{getLecturerName()}</FullName>
                        <Description abstractSize={abstractSize}>{abstract}</Description>
                        <Box>
                            <Tags>
                                {
                                    tags.map((tag, index) => <Tag key={index} data={tag} />)
                                }
                            </Tags>
                            {
                                !noButton && <Link to={`/my-studies/tasks/${id}`}><Button>Przejdź do zadania</Button></Link>
                            }
                        </Box>
                    </Col>
                    {/* <Image src={image} alt='' /> */}
                    <Col2>
                        <Image src={image} alt='' />
                        {
                            extraComponent && extraComponent
                        }
                    </Col2>
                </Row>
            </Container>
        </StyledTask>
    )
}

export default TaskRow

const StyledTask = styled.div`
    ${props => !props.noBackground && `
        background-image: url(${teacherRowBg});
        background-size: cover;
        background-repeat: no-repeat;
    `}
    padding: 65px 0px;
`

const Row = styled.div`
    max-width: 1170px;
    margin:auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    position: relative;
    @media only screen and (max-width: ${props => props.theme.mobile1}) {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
    ${props => props.extraLine && `
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            max-width: 250px;
            width: 100%;
            height: 10px;
            background-color: ${props.theme.colors.primary};
            transform: translateY(-65px);
        }
    `}
`

const Time = styled.div`
    font-size: 20px;
    line-height: 32px;
`

const Title = styled.div`
    font-family: 'Merriweather', serif;
    font-size: 24px;
    line-height: 38px;
    font-weight: bold;
    color: ${props => props.additional ? props.theme.colors.yellowGreen : props.theme.colors.primary};
    border-bottom-width: ${props => props.additional ? '20px' : '0px'};
    border-bottom-color: ${props => props.theme.colors.yellowGreen};
    border-bottom-style: solid;
    border-bottom-left-radius: 20px;
`

const FullName = styled.div`
    font-size: 20px;
    line-height: 32px;
    margin-left: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
    @media only screen and (max-width: ${props => props.theme.mobile1}) {
        margin-left: 0px;
    }
`

const Description = styled.div`
    font-size: 24px;
    font-weight: bold;
    line-height: 38px;
    font-family: 'Merriweather', serif;
    margin-bottom: 16px;
    margin-left: 30px;
    color: ${props => props.theme.colors.primary};
    ${props => props.abstractSize === 'small' && css`
            font-family: 'Lato', sans-serif;
            color: black;
            font-size: 20px!important;
            line-height: 32px;
            font-weight: normal;
    `}
    @media only screen and (max-width: ${props => props.theme.mobile1}) {
        margin-left: 0px;
    }
`

const Image = styled.img`
    display: block;
    width: 100%;
    max-width: 400px;
`

const Tags = styled.div`
    display: flex;
    gap: 35px;
    flex-wrap: wrap;
`

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    gap: 10px;
    margin-left: 30px;
    @media only screen and (max-width: ${props => props.theme.mobile1}) {
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        margin-left: 0;
    }
`

const Col = styled.div`
    max-width: 700px;
    width: 100%;
    @media only screen and (max-width: ${props => props.theme.mobile1}) {
        max-width: 100%;
    }
    
`

const Col2 = styled.div`
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
`

