import React from 'react'
import styled from 'styled-components'
import tasks from '../assets/images/tasks.svg'
import program from '../assets/images/program.svg'
import calender from '../assets/images/calender.svg'
import books from '../assets/images/books.svg'
import StageCard from './StageCard'
import useMediaQuery from '../Hooks/MediaQueryHook'
import Slider from '../components/Slider'
import {useStoreState} from "easy-peasy";


const exampleData = [
    {
        icon: tasks,
        title: 'Zadania',
        desc: 'Zajrzyj i zobacz, co na Ciebie czeka',
        link: '/my-studies/tasks'
    },
    {
        icon: program,
        title: 'Program',
        desc: 'Poznaj program swojej katedry',
        link: '/DYNAMIC_CHAIR'
    },
    {
        icon: calender,
        title: 'Terminarz',
        desc: 'Pamiętaj, aby dobrze zaplanować czas',
        link: '/my-studies/schedule',
    },
    {
        icon: books,
        title: 'Biblioteka',
        desc: 'Tu znajdziesz materiały do nauki',
        link: '/my-studies/library',
    }
]

const Stages = ({ data = exampleData, noBackground, ...props }) => {
    const isMobile = useMediaQuery(`(max-width: 500px`)
    const edition = useStoreState(state => state.user.edition)

    return (
        <>
            {
                isMobile ? (
                    <Slider
                        data={data}
                        component={<StageCard />}
                        breakpoints={{
                            1400: {
                                slidesPerView: 3
                            },
                            900: {
                                slidesPerView: 2
                            },
                        }}
                        isCenter={true}
                        bgColor={'white'}
                    />
                ) : (
                    <StagesStyled noBackground={noBackground} {...props}>
                        {
                            data.map((stage, index) => {

                                if(edition === "1" && stage.title === "Terminarz") {
                                stage = {
                                    ...stage,
                                    disabled: true,
                                }}
                                return (
                                    <StageCard
                                    data={stage}
                                    key={index}
                                    />
                                )
                            })
                        }
                    </StagesStyled>
                )
            }
        </>
    )
}

export default Stages

const StagesStyled = styled.div`
    ${props => !props.noBackground && `background-color: ${props.theme.colors.lightGray}`};
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
`