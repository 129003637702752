import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

const CounterDown = ({ deadline }) => {
        const calculateTimeLeft = (deadline) => {
            let deadlineDate = new Date(deadline);
            deadlineDate.setHours(0);
            let difference = deadlineDate - +new Date();
            let timeLeft = {}

            if (difference > 0) {
                timeLeft = {
                    days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, '0'),
                    hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0'),
                    minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, '0'),
                }
            } else {
                timeLeft = {
                    days: '00',
                    hours: '00',
                    minutes: '00',
                }
            }
            return timeLeft
    }
        useEffect(() => {
            const timer = setTimeout(() => {
                setTimeLeft(calculateTimeLeft(deadline))
            }, 60000)
            return () => clearTimeout(timer)
    })
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(deadline))

    return (
        <Wrapper>
            <h4>Zakończenie zadania za</h4>
            <Counter>
                <div>
                    <TimeLeft>
                        {timeLeft.days}
                        <span>dni</span>
                    </TimeLeft>
                </div>
                <div>
                    <TimeLeft>
                        {timeLeft.hours}
                        <span>godzin</span>
                    </TimeLeft>
                </div>
                <div>
                    <TimeLeft>
                        {timeLeft.minutes}
                        <span>minut</span>
                    </TimeLeft>
                </div>
            </Counter>
        </Wrapper>
    )
}

export default CounterDown

const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
  padding: 23px 32px;
  background-color: ${props => rgba(props.theme.colors.primary, 0.23)};
  border: 1px solid ${props => props.theme.colors.primary};
  position: relative;
  margin-bottom: 40px;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60%;
    height: 10px;
    background-color: ${props => props.theme.colors.primary};
  }
  h4 {
    color: #003B3E;
    font-family: 'Merriweather', serif!important;
    font-size: 26px;
  }
`;

const Counter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  > div:not(:last-child) {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 10px;
    &:after {
      content: ':';
      font-size: 35px;
      font-weight: bold;
      font-family: 'Merriweather', serif!important;
      height: 40px;
      margin-top: 17px;
    }
  }
`

const TimeLeft = styled.div`
  font-family: 'Merriweather', serif!important;
  font-size: 62px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  color: #E2000F;
  span {
    color: #003B3E;
    font-size: 25px;
    text-align: center;
    font-family: 'Merriweather', serif!important;
    margin-top: 5px;
  }
`